import React, { useContext } from 'react'
import { NavLink } from "react-router-dom";
import { navbar } from '../../constants/navigation'
import { FirebaseContext } from '../Firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
// import DebugSignIn from '../DebugSignIn'

const Navbar = () => {
    const firebase = useContext(FirebaseContext)
    // const [user, loading, error] = useAuthState(firebase.auth);
    const [user, loading] = useAuthState(firebase.auth);
    const logout = () => {
        firebase.auth.signOut();
    };
    const HandleSign = () => {
        if (user) return <button className="btn btn-link" onClick={logout}>Sign out</button>
        else return (
            <>
                <NavLink to={navbar.SIGNIN.path} className="btn btn-link">{navbar.SIGNIN.label}</NavLink>
                <NavLink to={navbar.SIGNUP.path} className="btn btn-link">{navbar.SIGNUP.label}</NavLink>
            </>)
    }

    return (
        <header className="navbar">
            <section className="navbar-section">
                <NavLink to={navbar.HOME.path} className="btn btn-link">{navbar.HOME.label}</NavLink>
                {!loading && <HandleSign />}
                {/* <DebugSignIn /> */}
            </section>
            <section className="navbar-section">
                <div className="input-group input-inline">
                    <input className="form-input" type="text" placeholder="search" />
                    <button className="btn btn-primary input-group-btn">Search</button>
                </div>
            </section>
        </header>)
}

export default Navbar