import React from 'react'
import DataForm from '../../../components/DataForm'
import entitySchema from './entitySchema'

const formSchema = {
    firstname: {
        type: "text",
        label: "firstname",
        required: true
    },
    lastname: {
        type: "text",
        label: "lastname",
        required: true
    },
    date: {
        type: "date",
        label: "Date",
        required: false
    },
}
const customOnSubmit = (values, { setSubmitting, resetForm, setStatus }) => {
    console.log(`Custom : ${JSON.stringify(values)}`);
    setSubmitting(false);
}

const EntitiesForm = () => {
    return (<>
        <h1>Nouvelle entitée</h1>
        <DataForm schema={entitySchema} submit={customOnSubmit} />
    </>)
}

export { EntitiesForm }