import React, { useContext, useState, useEffect } from 'react'
import { parse, getUnixTime } from 'date-fns'
import { useFormik } from 'formik';
import { FirebaseContext } from '../../../components/Firebase'
import { useAuthState } from 'react-firebase-hooks/auth';
import { useHistory } from 'react-router-dom'
import ROUTES from '../../../constants/routes'


const VisitCreatePage = () => {
    const firebase = useContext(FirebaseContext)
    const [fbUserId, setFbUserId] = useState('')
    const [user, loading, error] = useAuthState(firebase.auth);
    let history = useHistory()

    useEffect(() => {
        if (!loading) setFbUserId(user.uid)
        console.log("user", user)
    }, [])

    const formik = useFormik({
        initialValues: {
            userId: fbUserId,
            firstname: '',
            lastname: '',
            date: '',
        },
        onSubmit: (values) => {
            // alert(JSON.stringify(values, null, 2));
            console.log('firebase', firebase);

            firebase.db.collection("visits")
                .add({
                    ...values,
                    userId: fbUserId,
                    date: getUnixTime(parse(values.date, 'yyyy-MM-dd', new Date())),
                    createdAt: getUnixTime(new Date()),
                    editedAt: getUnixTime(new Date())
                })
                .then(function () {
                    console.log("Document successfully written!");
                    history.push(ROUTES.VISITS.path)
                })
                .catch(function (error) {
                    console.error("Error writing document: ", error);
                });
            // console.log(firebase.auth)
        },
    });
    return (
        <>
            <h2>Create a new visit</h2>
            {error && <p>{JSON.stringify(error)}</p>}
            <form onSubmit={formik.handleSubmit} >
                <div className="columns">
                    <div className="form-group column col-4 col-md-12">
                        <label className="form-label" htmlFor="firstname">Firstname</label>
                        <input className="form-input" type="text" name="firstname"
                            onChange={formik.handleChange}
                            value={formik.values.firstname}
                        />
                    </div>
                    <div className="form-group column col-4 col-md-12">
                        <label className="form-label" htmlFor="lastname">Lastname</label>
                        <input className="form-input" type="text" name="lastname"
                            onChange={formik.handleChange}
                            value={formik.values.lastname}
                        />
                    </div>
                    <div className="form-group column col-4 col-md-12">
                        <label className="form-label" htmlFor="date">Date</label>
                        <input className="form-input" type="date" name="date"
                            onChange={formik.handleChange}
                            value={formik.values.date}
                        />
                    </div>
                </div>
                <div className="columns">
                    <div className="column col-4 col-md-12">
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                </div>


            </form>
        </>

    );

}

export { VisitCreatePage }