import React, { useState } from 'react';
// import * as ROLES from '../../constants/roles';
// import { withAuthorization, withEmailVerification } from '../Session';
// import { compose } from 'recompose';


const macOsRFID = (raw) => {
    let output = ''
    const charMap = `à&é"'(§è!ç`
    for (let i = 0; i < raw.length; i++) {
        //console.log(`${raw[i]} index in charMap = ${charMap.indexOf(raw[i])}`);
        if (charMap.indexOf(raw[i]) < 0) {
            output += '#'
        } else {
            output += charMap.indexOf(raw[i]).toString()
        }

    }
    return parseInt(output)
}

const KeyConverter = (props) => {

    const [output, setOutput] = useState('');

    const handleInputChange = (e) => {
        console.log(isNaN(e.target.value))
        const converted = isNaN(e.target.value) ? macOsRFID(e.target.value) : parseInt(e.target.value)
        setOutput(converted)
    }

    return (
        <div>
            <h1>Key Converter</h1>
            <div className="form-group">
                <label className="form-label">Input</label>
                <input className="form-input" type="text" onChange={(e) => handleInputChange(e)} />
            </div>
            <div className="form-group">
                <label className="form-label">Output (dec)</label>
                <input className="form-input disable" type="text" value={output} readOnly disabled />
            </div>
            <div className="form-group">
                <label className="form-label">Output (hex)</label>
                <input className="form-input disable" type="text" value={output.toString(16)} readOnly disabled />
            </div>
        </div>
    );
}

const KeyConverterPage = (props) => {
    return (
        <>
            <KeyConverter />
        </>
    )
}

// const condition = authUser =>
//     authUser && !!authUser.roles[ROLES.ADMIN];


export default KeyConverterPage
// export default compose(
//     withEmailVerification,
//     withAuthorization(condition),
// )(KeyConverterPage);