const googleCalendars = [
    {
        label: 'Salle Créative',
        src: 'c_1888v2vuhmni6jvshifv2ippbgjek4gaetim4ojfe9n2spji@resource.calendar.google.com',
    },
    {
        label: 'Salle de montage',
        src: 'c_188463iem0qbui2ln0dj9b6me4b224gaetim4ojfe9n2spji@resource.calendar.google.com',
    },
    {
        label: 'Salle de réunions',
        src: 'c_188cc7g7cbo9qi8eif8ob690sq5oq4gaetim4ojfe9n2spji@resource.calendar.google.com',
    },
]

export default googleCalendars
