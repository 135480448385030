import ROUTES from './routes.js'

const navbar = {
    HOME: {
        label: 'Home',
        path: ROUTES.HOME.path
    },
    SIGNIN: {
        label: 'Signin',
        path: ROUTES.SIGNIN.path
    },
    SIGNUP: {
        label: 'Signup',
        path: ROUTES.SIGNUP.path
    },
}

const navitation = {
    HOME: {
        label: 'Home',
        path: ROUTES.HOME.path
    },
    CALENDARS: {
        label: 'Calendriers',
        path: ROUTES.CALENDARS.path,
        roles: ROUTES.CALENDARS.roles
    },
    ENTITIES: {
        label: 'Entités',
        path: ROUTES.ENTITIES.path,
        roles: ROUTES.ENTITIES.roles
    },
    USERS: {
        label: 'Utilisateurs',
        path: ROUTES.USERS.path,
        roles: ROUTES.USERS.roles
    },
    VISITS: {
        label: 'Visites',
        path: ROUTES.VISITS.path,
        roles: ROUTES.VISITS.roles
    },
    ACCESS: {
        label: 'Accès',
        path: ROUTES.ACCESS.path,
        roles: ROUTES.ACCESS.roles
    },
    // ACCORDIONS: {
    //     label: 'Properties',
    //     items: [{
    //         label: 'Properties List',
    //         path: ROUTES.HOME.path,
    //     },]
    // },
    // TRELLO: {
    //     label: 'Trello',
    //     path: ROUTES.TRELLO.path,
    //     roles: ROUTES.TRELLO.roles
    // },
    ADMIN: {
        label: 'Admin',
        path: ROUTES.ADMIN.path,
        roles: ROUTES.ADMIN.roles
    },
    KEYS: {
        label: 'Keys',
        path: ROUTES.KEYS.path,
        roles: ROUTES.KEYS.roles
    },
}

export default navitation
export { navbar }