import React, { useContext, useEffect, useState } from 'react'
import { VisitCreatePage } from './VisitCreatePage'
import { FirebaseContext } from '../../../components/Firebase'
import { useHistory, Link } from 'react-router-dom'
import { fromUnixTime, format } from 'date-fns'
// import format from 'date-fns/format'
import ROUTES from '../../../constants/routes'


const VisitsPage = () => {
    let history = useHistory()
    const firebase = useContext(FirebaseContext)
    const [visits, setVisits] = useState(null)
    const [isLoading, setLoading] = useState(true)
    useEffect(() => {
        firebase.db.collection("visits").orderBy('date', 'desc').get().then(function (querySnapshot) {
            let visitList = []
            querySnapshot.forEach((doc) => { visitList.push({ id: doc.id, ...doc.data() }) });
            console.log('visitList', visitList);
            setVisits(visitList)
            setLoading(false)
        })
            .catch(function (error) {
                console.log("Error getting documents: ", error);
            });
    }, [])

    return (
        <div>
            <h1>Liste des visites</h1>
            {isLoading}
            {visits && visits.length > 0 && (
                <>
                    <Link to={ROUTES.VISITS_CREATE.path} className="btn btn-primary">Create</Link>
                    <VisitsList data={visits} />
                </>)}
            {visits && visits.length === 0 && (
                <EmptyState
                    icon='people'
                    title="Vous n'avez aucune visite"
                    message='Cliquez sur "Nouvelle visite". '
                    action={() => { history.push(ROUTES.VISITS_CREATE.path) }}
                    actionLabel="Nouvelle visite" />)}
        </div>
    )
}

const EmptyState = ({ icon, title, message, action, actionLabel }) => {
    return (
        <div className="empty">
            {icon && (<div className="empty-icon">
                <i className={`icon icon-${icon}`}></i>
            </div>)}
            {title && (<p className="empty-title h5">{title}</p>)}
            {message && (<p className="empty-subtitle">{message}</p>)}
            {action && actionLabel && (<div className="empty-action">
                <button className="btn btn-primary" onClick={action}>{actionLabel}</button>
            </div>)}
        </div>
    )
}

const VisitsList = ({ data }) => {
    // const [properties, setProperties] = useState()
    return (
        <table className="table table-striped table-hover">
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Nom</th>
                    <th>Prénom</th>
                    <th>Statut</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {data && data.map((propety, key) => {
                    const { id, date, lastname, firstname, status } = propety
                    return (
                        <tr className="" key={key}>
                            <td>{format(fromUnixTime(date), "dd/MM/yyyy")}</td>
                            <td>{lastname}</td>
                            <td>{firstname}</td>
                            <td>{status}</td>
                            <td>{id}</td>
                        </tr>)
                })
                }
            </tbody>
        </table>
    )
}


export {
    VisitsPage,
    VisitCreatePage
}