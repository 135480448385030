import React, { useState, useReducer, useContext } from 'react'
import { FirebaseContext } from '../../../components/Firebase';
import { useHistory } from 'react-router-dom'


const Signup = () => {
    const firebase = useContext(FirebaseContext)
    let history = useHistory()

    const [error, setError] = useState(null)

    const [credentials, setCredentials] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        { username: '', password: '', firstname: '', lastname: '' }
    );

    const handleOnChange = event => {
        const { name, value } = event.target;
        setCredentials({ [name]: value });
    };

    const handleSubmit = e => {
        e.preventDefault()
        firebase.auth
            .createUserWithEmailAndPassword(credentials.username, credentials.password)
            .then((authUser) => {
                // firebase.db.ref(`users/${authUser.user.uid}`)
                //     .set({
                //         username: credentials.username,
                //         email: credentials.password,
                //         role: ''
                //     });
                console.log('user ID', authUser.user.uid)
                firebase.user(`${authUser.user.uid}`)
                    .set({
                        email: credentials.username,
                        // password: credentials.password,
                        firstname: credentials.firstname,
                        lastname: credentials.lastname,
                        role: ''
                    }).then(function () {
                        console.log("Document successfully written!");
                    })
                    .catch(function (error) {
                        console.error("Error writing document: ", error);
                    });

            })
            .then(() => {
                setCredentials({ username: '', password: '' })
                history.push('/')
            })
            .catch(function (error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                setError(errorCode, errorMessage)
                // ...
            });
    }

    return (
        <div className="container">
            <h1>Sign-up Page</h1>
            <form action="">
                <div className="form-group">
                    <label className="form-label" htmlFor="firstname">Firstname</label>
                    <input className="form-input" type="text" name="firstname" placeholder="Name"
                        onChange={(e) => handleOnChange(e)}
                        value={credentials.firstname}
                        autoComplete="firstname"
                    />
                </div>
                <div className="form-group">
                    <label className="form-label" htmlFor="lastname">Lastname</label>
                    <input className="form-input" type="text" name="lastname" placeholder="Name"
                        onChange={(e) => handleOnChange(e)}
                        value={credentials.lastname}
                        autoComplete="lastname"
                    />
                </div>
                <div className="form-group">
                    <label className="form-label" htmlFor="username">Email</label>
                    <input className="form-input" type="text" name="username" placeholder="Name"
                        onChange={(e) => handleOnChange(e)}
                        value={credentials.username}
                        autoComplete="username"
                    />
                </div>
                <div className="form-group">
                    <label className="form-label" htmlFor="password">Password</label>
                    <input className="form-input" type="password" name="password" placeholder="Name"
                        onChange={(e) => handleOnChange(e)}
                        value={credentials.password}
                        autoComplete="current-password" />
                </div>
                {error && <p> {error}</p>}
                <div className="form-group">
                    <input type="submit" className="btn btn-primary" value="Signup"
                        onClick={(e) => handleSubmit(e)}
                    />
                </div>
            </form>
        </div>
    )
}

export default Signup