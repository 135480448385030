const schema = {
    email: {
        type: String,
        required: true,
        validation: 'email',
        label: 'Email',
        input: {
            type: '',
            name: '',
            label: '',
        }
    },
    entityId: {
        type: String,
        label: 'Entité',
    },
    firstname: {
        type: String,
        label: 'Prénom',
    },
    lastname: {
        type: String,
        label: 'Nom',
    },
    role: {
        type: String,
        label: 'Role',
    },
}

export default schema