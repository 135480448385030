import React from 'react'
// import { NavLink, Switch, Route, useParams } from 'react-router-dom'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    // Link,
    NavLink,
    useParams,
    useRouteMatch
} from "react-router-dom";
import EmptyState from '../../../components/EmptyState'
import GoogleCalendarWidget from './GoogleCalendarWidget'
import googleCalendars from '../../../constants/googleCalendars'
import ROUTES from '../../../constants/routes'
import './Calendars.scss'

const CalendarsPage = () => {
    let { path } = useRouteMatch();
    return (
        <Router>
            <div>
                <h2 id="title">Calendriers</h2>
                <ul className="tab tab-block">
                    {googleCalendars.map((cal, key) =>
                        <li className="tab-item" key={key}>
                            <NavLink to={`${ROUTES.CALENDARS.path}/${key}`} >{cal.label}</NavLink>
                        </li>
                    )}
                </ul>

                <Switch>
                    <Route exact path={path}>
                        <EmptyState icon="time" title="Sélectionnez un calendrier" message="Merci de sélectionner un calendrier ci-dessus." />
                    </Route>
                    <Route path={`${path}/:topicId`} exact component={Calendar} />
                </Switch>
            </div>
        </Router>
    );
}


function Calendar() {
    let { topicId } = useParams();

    return (
        <div>
            <h3>{googleCalendars[topicId].label}</h3>
            {topicId && <GoogleCalendarWidget calendarId={googleCalendars[topicId].src} />}
        </div>
    );
}
export default CalendarsPage