import React from 'react'
import { Route, } from "react-router-dom";
// import TwoColumnsRoute from '../layouts/TwoColumnsRoute'
// import SingleColumnRoute from '../layouts/SingleColumnRoute'

const RouteLayedOut = ({ layout, component: Component, ...rest }) => {
    const Layout = layout
    return (
        <Route {...rest} >
            <Layout>
                <Component />
            </Layout>
        </Route>
    )
}


export default RouteLayedOut