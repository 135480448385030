import React from 'react'
// import Sidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'

const SingleColumnRoute = ({ children }) => {
    return (
        <>
            <div className="app-container off-canvas off-canvas-sidebar-show">
                {/* <!-- off-screen toggle button --> */}
                <a className="off-canvas-overlay" href="#close">&#128512</a>
                <div className="off-canvas-content">
                    <div className="app-content">
                        <Navbar />
                        <div className="container">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default SingleColumnRoute