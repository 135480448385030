import React, { useState } from 'react'

const fakeDate = [
    {
        date: '29/10/2020 12:30',
        door: 'Rue',
        user: 'Jean',
        direction: 'In',
    },
    {
        date: '29/10/2020 12:30',
        door: 'Sas',
        user: 'Jean',
        direction: 'In',
    },
    {
        date: '29/10/2020 15:26',
        door: 'Sas',
        user: 'REX',
        direction: 'Out',
    },
    {
        date: '29/10/2020 15:26',
        door: 'Rue',
        user: 'REX',
        direction: 'Out',
    },
]

const AccessEvents = () => {
    const [events, setEvents] = useState(fakeDate)
    return (<>
        <h2>Evenement</h2>
        <table className="table">
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Porte</th>
                    <th>ID</th>
                    <th>Direction</th>
                </tr>
            </thead>
            <tbody>
                {events.map((event, key) => {
                    const { date, door, user, direction, } = event
                    return (
                        <tr key={key}>
                            <td>{date}</td>
                            <td>{door}</td>
                            <td>{user}</td>
                            <td>{(direction === 'Out') ? (<>&#9650;</>) : (<>&#9660;</>)} {direction}</td>
                        </tr>)
                })}
            </tbody>
        </table>
    </>)
}

export default AccessEvents