const EmptyState = ({ icon, title, message, action, actionLabel }) => {
    return (
        <div className="empty">
            {icon && (<div className="empty-icon">
                <i className={`icon icon-${icon}`}></i>
            </div>)}
            {title && (<p className="empty-title h5">{title}</p>)}
            {message && (<p className="empty-subtitle">{message}</p>)}
            {action && actionLabel && (<div className="empty-action">
                <button className="btn btn-primary" onClick={action}>{actionLabel}</button>
            </div>)}
        </div>
    )
}

export default EmptyState