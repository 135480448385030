import React, { useContext, useEffect, useState } from 'react'
import { FirebaseContext } from '../../../components/Firebase'
import EmptyState from '../../../components/EmptyState'
import DataTable from '../../../components/DataTable'
import userSchema from './userSchema'

const Users = () => {
    const firebase = useContext(FirebaseContext)
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const limit = 20
    useEffect(() => {
        getEntities()
    }, [])

    const getEntities = () => {
        firebase.db.collection('users')
            .get()
            .then(querySnapshot => {
                querySnapshot.forEach(doc => {
                    setData(prev => ([...prev, { id: doc.id, ...doc.data() }]))
                    setLoading(false)
                })
            })
            .catch(err => {
                console.log(err.message)
                setLoading(false)
            })
    }
    return (
        <>
            {loading && <div className="loading loading-lg"></div>}
            {!loading && data.length > 0 && (
                <div>
                    <DataTable schema={userSchema} data={data} displayId={true} />
                    <ul className="pagination">
                        <li className="page-item disabled">
                            <span>Previous</span>
                        </li>
                        <li className="page-item">
                            <span>Next</span>
                        </li>
                    </ul>
                </div>)}

            {!loading && data.length === 0 && (
                <EmptyState
                    icon='people'
                    title="Vous n'avez aucun utilisateur"
                    message='Cliquez sur "Créer". '
                    action={() => { }}
                    actionLabel="Créer" />
            )}
        </>
    )

}

export { Users }