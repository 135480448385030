import './App.scss';
import Routers from '../../router'
import UserProvider from '../UserProvider'


function App() {
  return (
    <UserProvider>
      <Routers />
    </UserProvider>
  );
}

export default App;