import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

class Firebase {
    constructor() {
        app.initializeApp(config);
        this.auth = app.auth();
        this.db = app.firestore();
        this.authUser = {}
    }

    setAuthUser = (info) => {
        this.authUser = info
    }

    users = () => this.db.collection("users");
    // user = uid => this.db.ref(`users/${uid}`); //Firebase RTB
    user = uid => this.db.collection("users").doc(uid); //Firestore

    properties = () => this.db.collection("properties");


}

export default Firebase;