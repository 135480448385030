const schema = {
    isCompany: {
        type: Boolean,
        label: 'Type',
        field: { type: 'checkbox' },
    },
    company: {
        type: String,
        label: 'Société',
    },
    firstname: {
        type: String,
        required: true,
        validation: 'email',
        label: 'Prénom',
        input: {
            type: '',
            name: '',
            label: '',
        }
    },
    lastname: {
        type: String,
        label: 'Nom',
    },
    street: {
        type: String,
        label: 'Rue',
    },
    zip: {
        type: String,
        label: 'Code postal',
    },
    city: {
        type: String,
        label: 'Ville',
    },
    country: {
        type: String,
        label: 'Pays',
    },
    registreringNb: {
        type: String,
        label: 'N° d\'enregistrement',
    },
    registreringOrg: {
        type: String,
        label: 'Organisme',
    },
    title: {
        type: String,
        label: 'Qualité',
    },
}

export default schema









