import React, { useState, useReducer, useContext } from 'react'
import { FirebaseContext } from '../../../components/Firebase';
import { useHistory } from 'react-router-dom'


const Signin = () => {
    const firebase = useContext(FirebaseContext)
    let history = useHistory()

    const [error, setError] = useState(null)

    const [credentials, setCredentials] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        { username: '', password: '' }
    );

    const handleOnChange = event => {
        const { name, value } = event.target;
        setCredentials({ [name]: value });
    };

    const handleSubmit = e => {
        e.preventDefault()
        firebase.auth
            .signInWithEmailAndPassword(credentials.username, credentials.password)
            .then(() => history.push('/'))
            .catch(function (error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                setError(errorCode, errorMessage)
                // ...
            });
    }

    return (
        <div className="container">
            <h1>Signin Page</h1>
            <form action="">
                <div className="form-group">
                    <label className="form-label" htmlFor="username">Email</label>
                    <input className="form-input" type="text" name="username" placeholder="Name"
                        onChange={(e) => handleOnChange(e)}
                        value={credentials.username}
                        autoComplete="username"
                    />
                </div>
                <div className="form-group">
                    <label className="form-label" htmlFor="password">Password</label>
                    <input className="form-input" type="password" name="password" placeholder="Name"
                        onChange={(e) => handleOnChange(e)}
                        value={credentials.password}
                        autoComplete="current-password" />
                </div>
                {error && <p> {error}</p>}
                <div className="form-group">
                    <input type="submit" className="btn btn-primary" value="Signin"
                        onClick={(e) => handleSubmit(e)}
                    />
                </div>
            </form>
        </div>
    )
}

export default Signin