import React, { useContext } from 'react'
import { NavLink } from "react-router-dom";
// import ROUTES from '../../constants/routes'
import navigation from '../../constants/navigation'
import { UserContext } from '../UserProvider'

const AccordionComp = ({ label, items, index }) => {
    return (
        <li className="menu-item accordion">
            <input type="checkbox" id={`accordion-${index}`} name="accordion-checkbox" hidden />
            <label className="accordion-header" htmlFor={`accordion-${index}`}>
                {/* <i className="icon icon-arrow-right mr-1"></i> */}
                {label}
            </label>
            <div className="accordion-body">
                <ul className="menu menu-nav">
                    {items && items.map(({ label, path }, key) => (
                        <li className="menu-item" key={key}><NavLink exact to={path}>{label}</NavLink></li>
                    ))}
                </ul>
            </div>
        </li>
    )
}

const Sidebar = () => {
    // const [user, setUser] = useState(null)
    const user = useContext(UserContext)
    return (
        <div id="sidebar-id" className="off-canvas-sidebar">
            <div className="app-brand">
                <a className="app-logo" href="../index.html">
                    <h2>Taiko App</h2>
                </a>
            </div>
            <div className="app-nav">
                <ul className="menu-nav menu">
                    {
                        Object.keys(navigation).map((nav, key) => {
                            const { label, path, items, roles } = navigation[nav]
                            let returned = null
                            if (roles && roles.length > 0) {
                                if (user && roles && roles.includes(user.role)) returned = (<li className="menu-item" key={key}><NavLink exact to={path}>{label}</NavLink></li>)
                            }
                            else {
                                if (path) returned = (<li className="menu-item" key={key}><NavLink exact to={path}>{label}</NavLink></li>)
                                else returned = (<AccordionComp label={label} items={items} index={nav} key={key} />)
                            }
                            return returned
                        })
                    }
                </ul>
                <p>Role {user && user.role}</p>
            </div>
        </div>
    )
}

export default Sidebar