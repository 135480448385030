import React, {
    useContext,
    useEffect,
    useState
} from 'react'
import { useHistory } from 'react-router-dom'
import { FirebaseContext } from '../../../components/Firebase'
import EmptyState from '../../../components/EmptyState'
import DataTable from '../../../components/DataTable'
import entitySchema from './entitySchema'
import ROUTES from '../../../constants/routes'

const EntitiesPage = () => {
    const firebase = useContext(FirebaseContext)
    let history = useHistory()
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const limit = 20

    useEffect(() => {
        getEntities()
    }, [])

    const getEntities = () => {
        firebase.db.collection('entities')
            .get()
            .then(querySnapshot => {
                querySnapshot.forEach(doc => {
                    // console.log({ id: doc.id, ...doc.data() });
                    setData(prev => ([...prev, { id: doc.id, ...doc.data() }]))
                    setLoading(false)
                })
            })
            .catch(err => {
                console.log(err.message)
                setLoading(false)
            })
    }

    const entitiesActionList = [
        {
            label: 'Editer',
            method: (date, event) => { alert(JSON.stringify(date)); console.log(event) },
            class: 'btn-primary btn-sm mr-1',
        },
        {
            label: 'Action2',
            method: (date, event) => { if (window.confirm(`Confirmation ${date.id}`)) { console.log(event) } },
            class: 'btn-sm',
        },
    ]

    const filteredKeys = ['street', 'zip', 'registreringOrg', 'title']

    return (
        <>
            {loading && <div className="loading loading-lg"></div>}
            {!loading && data.length > 0 && (
                <div>
                    <h2>Entités</h2>
                    <div className="mb-2">
                        <input
                            type="button"
                            className="btn btn-primary"
                            onClick={() => { history.push(ROUTES.ENTITIES_CREATE.path) }} value='Créer' />

                    </div>
                    <DataTable schema={entitySchema} data={data} displayId={false} actions={entitiesActionList} filteredKeys={filteredKeys} />
                </div>)}

            {!loading && data.length === 0 && (
                <EmptyState
                    icon='people'
                    title="Vous n'avez aucun utilisateur"
                    message='Cliquez sur "Créer". '
                    action={() => { }}
                    actionLabel="Créer" />
            )}
        </>
    )
}

export { EntitiesPage }