import React, { useEffect, useState, useContext } from 'react'
import {
    Form,
    TextField,
    TextAreaField,
    DateField,
    SelectField,
    Checkbox,
    SubmitButton
} from '../FormikElement';
import * as Yup from 'yup';

const defaultOnSubmit = (values, { setSubmitting, resetForm, setStatus }) => {
    console.log(values);
    setSubmitting(false);
}

const DataForm = ({ schema = {}, submit = defaultOnSubmit }) => {

    const [formData, setFormData] = useState();
    const [validationSchema, setValidationSchema] = useState({});

    useEffect(() => {
        console.log('schema', schema)
        initForm(schema);
    }, [schema])

    const initForm = (formSchema) => {
        let _formData = {};
        let _validationSchema = {};

        for (var key of Object.keys(formSchema)) {
            _formData[key] = "";
            /*  if (schema[key].type === "text") {
               _validationSchema[key] = Yup.string();
             } else if (schema[key].type === "email") {
               _validationSchema[key] = Yup.string().email()
             } else if (schema[key].type === "select") {
               _validationSchema[key] = Yup.string().oneOf(schema[key].options.map(o => o.value));
             } */
            switch (formSchema[key].type) {
                case 'email':
                    _validationSchema[key] = Yup.string().email()
                    break;
                case 'textarea':
                    break;
                case 'select':
                    // _validationSchema[key] = Yup.string().oneOf(schema[key].options.map(o => o.label));
                    break;
                default:
                    _validationSchema[key] = Yup.string();
                    break;
            }

            if (formSchema[key].required) {
                _validationSchema[key] = _validationSchema[key].required('Required');
            }
        }

        setFormData(_formData);
        setValidationSchema(Yup.object().shape({ ..._validationSchema }));
    }

    const getFormElement = (elementName, elementSchema) => {
        const { type, ...rest } = elementSchema //to remove type property from schema
        const props = {
            name: elementName,
            ...rest
        };

        // if (elementSchema.type === "text" || elementSchema.type === "email") {
        if ('field' in elementSchema) {
            if (['text', 'email'].includes(elementSchema.field.type)) return <TextField {...props} />
            if (elementSchema.field.type === "textarea") return <TextAreaField  {...props} />
            if (elementSchema.field.type === "date") return <DateField  {...props} />
            if (elementSchema.field.type === "select") return <SelectField  {...props} />
            if (elementSchema.field.type === "checkbox") return <Checkbox  {...props} />
        }
        else return <TextField  {...props} />
    }
    /* 
        const onSubmit = (values, { setSubmitting, resetForm, setStatus }) => {
            const additionalData = {
                isCompany: false,
                name: `${values.lastname} ${values.firstname}`,
                members: [],
                createdAt: getUnixTime(new Date()),
                editedAt: getUnixTime(new Date()),
            }
            const newEntity = { ...values, ...additionalData }
            console.log(newEntity);
            // firebase.db.collection('entities').add(newEntity)
            //     .then(function () {
            //         console.log("Document successfully written!");
            //         history.push(ROUTES.ENTITIES.path)
            //     })
            //     .catch(function (error) {
            //         console.error("Error writing document: ", error);
            //     });
            setSubmitting(false);
        } */

    return (
        <>
            {formData && (<Form
                enableReinitialize
                initialValues={formData}
                validationSchema={validationSchema}
                onSubmit={submit}
            >
                <div className="mb-2">
                    {Object.keys(schema).map((key, ind) => (
                        <div key={key}>
                            {getFormElement(key, schema[key])}
                        </div>
                    ))}
                </div>
                <div className="mt-2">
                    <SubmitButton title="Submit" />
                </div>
            </Form>)}
        </>
    );
}

export default DataForm
