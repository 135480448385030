import React from 'react';
import {
    Formik,
    Form as FormikForm,
    Field,
    ErrorMessage,
    useFormikContext,
    // useField,
    // useFormik
} from 'formik';

export function Form(props) {
    return (
        <Formik
            {...props}
        >
            <FormikForm className="needs-validation" noValidate="">
                {props.children}
            </FormikForm>
        </Formik>)
}

export function TextField({ name, label, placeholder, ...rest }) {
    return (
        <div className="form-group">
            {label && <label className="form-label" htmlFor={name}>{label}</label>}
            <Field
                className="form-input"
                type="text"
                name={name}
                id={name}
                placeholder={placeholder || ""}
                {...rest}
            />
            <ErrorMessage name={name} render={msg => <div style={{ color: 'red' }} >{msg}</div>} />
        </div>
    )
}
export function TextAreaField(props) {
    const { name, label, placeholder, ...rest } = props
    return (
        <div className="form-group">
            {label && <label htmlFor={name}>{label}</label>}
            <Field
                className="form-control"
                component="textarea"
                name={name}
                id={name}
                placeholder={placeholder || ""}
                {...rest}
            />
            <ErrorMessage name={name} render={msg => <div style={{ color: 'red' }} >{msg}</div>} />
        </div>
    )
}
export function DateField(props) {
    const { name, label, placeholder, ...rest } = props
    return (
        <div className="form-group">
            {label && <label className="form-label" htmlFor={name}>{label}</label>}
            <Field
                className="form-input"
                type="date"
                name={name}
                id={name}
                placeholder={placeholder || ""}
                {...rest}
            />
            <ErrorMessage name={name} render={msg => <div style={{ color: 'red' }} >{msg}</div>} />
        </div>
    )
}

export function SelectField(props) {
    const { name, label, options } = props
    return (
        <div className="form-group">
            {label && <label htmlFor={name}>{label}</label>}
            <Field
                as="select"
                id={name}
                name={name}
                className="form-select"
            >
                <option disabled >Choose...</option>
                {options.map((optn, index) => <option value={optn.value} label={optn.label || optn.value} key={index} />)}
            </Field>
            <ErrorMessage name={name} render={msg => <div style={{ color: 'red' }} >{msg}</div>} />
        </div>
    )
}

export function Checkbox(props) {
    const { name, label, options } = props
    let checkbox
    if (options) {
        checkbox =
            <div className="form-group">
                <label className="form-label">{label}</label>
                {
                    options.map((optn, index) => (
                        <div className="form-check" key={index} >
                            <Field
                                // as="select"
                                type="checkbox"
                                id={`${name}-${index}`}
                                name={name}
                                className="form-check-input"
                                value={optn.value}
                            />
                            <label className="form-check-label" htmlFor={`${name}-${index}`}>{optn.label}</label>
                            <ErrorMessage name={name} render={msg => <div style={{ color: 'red' }} >{msg}</div>} />
                        </div>
                    ))
                }
            </div>;
    } else {
        checkbox = <div className="form-group"><div className="form-check">
            <Field
                type="checkbox"
                id={`${name}`}
                name={name}
                className="form-check-input"
            />
            <label className="form-check-label" htmlFor={`${name}`}>{label}</label>
            <ErrorMessage name={name} render={msg => <div style={{ color: 'red' }} >{msg}</div>} />
        </div></div>;
    }
    return <>{checkbox}</>
}

export function SubmitButton(props) {
    const { title, ...rest } = props;
    const { isSubmitting } = useFormikContext();

    return (
        <div className="form-group">
            <button className="btn  btn-primary" type="submit" {...rest} disabled={isSubmitting}>{title}</button>
        </div>
    )
}