import React, { } from 'react'
// import googleCalendars from '../../../constants/googleCalendars'


const generateCalendarUrl = (src, options = {}) => {
    const defaultOptions = {
        // height: 600,
        // wkst: 2,
        // bgcolor: '%23000000',
        // ctz: 'Europe%2FParis',
        ctz: 'Europe/Paris',
        // color: '#F6BF26',
        showTitle: 1,
        // showNav: 1,
        showCalendars: 0,
        // showTabs: 1,
        // showDate: 1,
        showPrint: 0,
        mode: "WEEK" //AGENDA, WEEK, MONTH
        // title: 'Marry'
    }
    const _base = 'https://calendar.google.com/calendar/embed'
    const queryParams = Object.entries({ ...defaultOptions, ...options }).map(([key, val]) => `${key}=${val}`).join('&');
    const outputUrl = `${_base}?src=${encodeURIComponent(src)}&${queryParams}`
    // console.log('calUrl:', outputUrl);
    return outputUrl
}

const IFrameComp = ({ url }) => {
    return (<>
        <iframe src={url}
            title="iFrame"
            // style={{ "border": 0 }}
            width="100%"
            height="600"
            frameBorder="0"
            scrolling="no"
        />
    </>)
}

const GoogleCalendarWidget = ({ calendarId, options = {} }) => <IFrameComp url={generateCalendarUrl(calendarId, options)} />

export default GoogleCalendarWidget