import React, { useState, useEffect, useContext } from "react";
import { FirebaseContext } from "../Firebase";

export const UserContext = React.createContext({ user: null });
UserContext.displayName = 'UserContext';


const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null)
    const firebase = useContext(FirebaseContext)

    useEffect(() => {
        const unsubscribe = firebase.auth.onAuthStateChanged(userAuth => {
            if (userAuth) firebase.user(userAuth.uid).get()
                .then(doc => {
                    if (doc.exists) {
                        console.log("Document data:", doc.data());
                        setUser({ ...doc.data(), ...userAuth })
                    } else {
                        alert("No such document!");
                        setUser(null)
                    }
                })

            else setUser(null)
        })
        return () => unsubscribe()

    }, [firebase.auth])

    return (
        <UserContext.Provider value={user}>
            {children}
        </UserContext.Provider>
    );
}
export default UserProvider;