import SingleColumnRoute from '../containers/layouts/SingleColumnRoute'
// import TwoColumnsRoute from '../containers/layouts/TwoColumnsRoute'
import Home from '../containers/views/Home'
import Signin from '../containers/views/Signin'
import Signup from '../containers/views/Signup'
import CalendarsPage from '../containers/views/Calendars'
import AccessControl from '../containers/views/AccessControl'
import KeyConverter from '../components/KeyConverter'
// import Trello from '../containers/views/Trello'
import {
    EntitiesPage,
    EntitiesForm
} from '../containers/views/Entities'
import { VisitsPage, VisitCreatePage } from '../containers/views/Visits'
import { Users, } from '../containers/views/Users'


import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'

const TwoColumnsRoute = ({ children }) => {
    return (
        <>
            <div className="app-container off-canvas off-canvas-sidebar-show">
                {/* <!-- off-screen toggle button --> */}
                <a className="off-canvas-toggle btn btn-primary btn-action" href="#sidebar-id">
                    <i className="icon icon-menu"></i>
                </a>
                <Sidebar />
                <a className="off-canvas-overlay" href="#close">&#128512</a>
                <div className="off-canvas-content">
                    <Navbar />
                    <div className="app-content">

                        <div className="container">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const MokeComp = () => <div>Hello World</div>

const routes = {
    HOME: {
        'path': '/',
        'component': Home,
        'layout': TwoColumnsRoute,
    },
    SIGNIN: {
        'path': '/signin',
        'component': Signin,
        'layout': SingleColumnRoute,
    },
    SIGNUP: {
        'path': '/signup',
        'component': Signup,
        'layout': SingleColumnRoute,
    },
    ACCESS: {
        'path': '/access',
        'component': AccessControl,
        'layout': TwoColumnsRoute,
        'roles': ['ADMIN', 'MANAGER', 'USER'],
    },
    CALENDARS: {
        'path': '/calendars',
        'component': CalendarsPage,
        'layout': TwoColumnsRoute,
        'roles': ['ADMIN', 'MANAGER', 'USER'],
    },
    ENTITIES: {
        'path': '/entities',
        'component': EntitiesPage,
        'layout': TwoColumnsRoute,
        'roles': ['ADMIN', 'MANAGER'],
    },
    ENTITIES_CREATE: {
        'path': '/entities/new',
        'component': EntitiesForm,
        'layout': TwoColumnsRoute,
        'roles': ['ADMIN', 'MANAGER'],
    },
    // TRELLO: {
    //     'path': '/trello',
    //     'component': Trello,
    //     'layout': TwoColumnsRoute,
    //     'roles': ['ADMIN', 'MANAGER'],
    // },
    USERS: {
        'path': '/users',
        'component': Users,
        'layout': TwoColumnsRoute,
        'roles': ['ADMIN', 'MANAGER'],
    },
    VISITS: {
        'path': '/visits',
        'component': VisitsPage,
        'layout': TwoColumnsRoute,
        'roles': ['ADMIN', 'MANAGER', 'USER'],
    },
    VISITS_CREATE: {
        'path': '/visits/new',
        'component': VisitCreatePage,
        'layout': TwoColumnsRoute,
        // 'roles': ['ADMIN', 'MANAGER', 'USER'],
    },
    ADMIN: {
        'path': '/admin',
        'component': MokeComp,
        'layout': TwoColumnsRoute,
        'roles': ['ADMIN']
    },
    KEYS: {
        'path': '/keys',
        'component': KeyConverter,
        'layout': TwoColumnsRoute,
        'roles': ['ADMIN']
    }
    // PROPERTIES_DETAILS: {
    //     component: PropertiesPage,
    //     path: '/properties/:id',
    //     layout: TwoColumnsRoute,
    // },
}
export default routes