import React from 'react'
import AccessEvents from './AccessEvents'

const AccessControlPage = () => {
    return (<>
        <h1>Accès</h1>
        <h2>Fake</h2>
        <AccessEvents />
    </>)
}

export default AccessControlPage