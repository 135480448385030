import React, { useContext } from 'react'
import {
    BrowserRouter as Router,
    Switch,
} from "react-router-dom";
// import { FirebaseContext } from '../components/Firebase';
import { UserContext } from '../components/UserProvider'
import RouteLayedOut from '../containers/layouts'
import TwoColumnsRoute from '../containers/layouts/TwoColumnsRoute'
// import SingleColumnRoute from '../containers/layouts/SingleColumnRoute'
import ROUTES from '../constants/routes'

const Routers = () => {
    // const firebase = useContext(FirebaseContext)
    const user = useContext(UserContext)
    return (
        <Router>
            <Switch>
                {
                    Object.keys(ROUTES).map((route, key) => {
                        const { layout, path, component, roles } = ROUTES[route]
                        let returnedRoute = null
                        if (roles && roles.length > 0) {
                            if (user && roles && roles.includes(user.role)) returnedRoute = (<RouteLayedOut layout={layout} path={path} component={component} exact key={key} />)
                            else returnedRoute = null
                        }
                        else returnedRoute = (<RouteLayedOut layout={layout} path={path} component={component} exact key={key} />)

                        return (returnedRoute)
                    })
                }
                {/* <RouteLayedOut layout={TwoColumnsRoute} path={ROUTES.SIGNUP.path} component={ROUTES.SIGNUP.component} /> */}
                {/* <RouteLayedOut layout={TwoColumnsRoute} path={ROUTES.HOME.path} exact component={ROUTES.HOME.component} /> */}
                {/* <SingleColumnRoute path={ROUTES.SIGNIN.path} component={ROUTES.SIGNIN.component} /> */}
            </Switch>
        </Router>
    )
}

export default Routers