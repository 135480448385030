import React from 'react'

const DataTable = ({ schema, data, displayId = true, actions = null, filteredKeys = [] }) => {
    const limit = 20
    const sch = displayId ? { id: { label: 'id' }, ...schema } : schema
    const schemaKeyArr = Object.keys(sch).filter(key => !filteredKeys.includes(key))
    return schema && (
        <table className="table table-striped table-hover table-scroll">
            <thead>
                <tr>
                    {schemaKeyArr.map((property, k) => (<th key={k}>{'label' in sch[property] ? sch[property].label : property}</th>))}
                    <th>{actions && (<>Actions</>)}</th>
                </tr>
            </thead>
            <tbody>
                {Object.keys(data).map((row, key) => (
                    <tr key={key}>
                        {schemaKeyArr.map((property, key) => (<td key={key}>{data[row][property]}</td>))}
                        <td>{actions && actions.map((action, key) => (
                            <button
                                key={key}
                                onClick={(e) => action.method(data[row], e)}
                                className={`btn ${action.class}`}
                            >
                                {action.label}
                            </button>
                        ))}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default DataTable